hr.transparent {
  border-top: 1px solid transparent;
}

.d-table {
  display: table;
}

.d-100vh-va-middle {
  display: table-cell;
  height: 100vh;
  vertical-align: middle;
}
