header.navbar {
  position: relative;
  height: $navbar-height;
  padding: 0;
  line-height: $navbar-height;
  background-color: $navbar-bg;
  @include borders($navbar-border);

  .container-fluid {
    padding: 0;
  }

  .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0 !important;
    text-align: center;

    .nav-link {
      i {
        vertical-align: middle;
      }

      .tag {
        position: absolute;
        top: 9px;
        right: 7px;
      }
    }
  }

  .img-avatar {
    height: $navbar-height - 20px;
    margin: 0 10px;
  }

  .dropdown-menu {
    padding-bottom: 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;
  }

  a.navbar-brand {
    display: inline-block;
    width: $navbar-brand-width;
    height: $navbar-height;
    padding: $navbar-padding-y $navbar-padding-x;
    background-color: $navbar-brand-bg;
    background-image: $navbar-brand-logo;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $navbar-brand-logo-size;
    @include borders($navbar-brand-border);
  }
}

header.navbar {
  .navbar-brand {
    margin-right: 0;
  }
}

.navbar-toggler {
  padding: 0 0.75em;
  line-height: $navbar-height - 4px;
}
.aside-toggle {
  @extend .navbar-toggler;
  padding: 0 0.75em;
  line-height: $navbar-height - 4px;
}

header.navbar {
  .navbar-nav {
    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.navbar-brand {
  color: $navbar-active-color;

  @include hover-focus {
    color: $navbar-active-color;
  }
}

.navbar-nav {
  .nav-link {
    color: $navbar-color;

    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .open > .nav-link,
  .active > .nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
    }
  }
}

.navbar-divider {
  background-color: rgba(0, 0, 0, 0.075);
}
