@use 'sass:math';

:root {
  --sidebar-bg: rgb(22, 38, 54);
  --sidebar-text: #ffffff;
  --sidebar-bg-active: #1d3348;
  --sidebar-bg-hover: #67a5ee;
  --sidebar-bg-dropdown: rgba(0, 0, 0, 0.2);
}

body:not(.top-nav) {
  .sidebar {
    position: absolute;
    top: 0;
    //left: 0;
    z-index: 1;
    display: inline !important;
    width: $sidebar-width;
    height: 100%;
    padding: $sidebar-padding;
    color: var(--sidebar-text);
    background: var(--sidebar-bg);
    @include borders($sidebar-borders);

    .sidebar-close {
      position: absolute;
      right: 0;
      display: none;
      padding: 0 $spacer-x;
      font-size: 24px;
      font-weight: 800;
      line-height: $navbar-height;
      color: var(--sidebar-text);
      background: 0;
      border: 0;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

    .sidebar-nav {
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      &::-webkit-scrollbar {
        width: 10px;
        margin-left: -10px;
        -webkit-appearance: none;
      }

      // &::-webkit-scrollbar-button {    }

      &::-webkit-scrollbar-track {
        background-color: var(--sidebar-bg-active);
        border-right: 1px solid var(--sidebar-bg);
        border-left: 1px solid var(--sidebar-bg);
      }

      // &::-webkit-scrollbar-track-piece {    }

      &::-webkit-scrollbar-thumb {
        height: 50px;
        background-color: var(--sidebar-bg-hover);
        background-clip: content-box;
        border-color: transparent;
        border-style: solid;
        border-width: 1px 2px;
      }

      // &::-webkit-scrollbar-corner {    }

      // &::-webkit-resizer {    }
    }

    ul.nav {
      width: $sidebar-width;

      li.nav-title {
        padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
        font-size: 11px;
        font-weight: 600;
        color: var(--sidebar-text);
        text-transform: uppercase;
      }

      li.divider {
        height: 10px;
      }

      li.nav-item {
        position: relative;
        margin: 0;
        transition: background 0.3s ease-in-out;

        ul {
          // display: none;
          max-height: 0;
          padding: 0;
          margin: 0;
          overflow-y: hidden;
          transition: max-height 0.3s ease-in-out;

          li {
            padding: 0;
            list-style: none;
          }
        }

        a.nav-link {
          display: block;
          padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
          color: var(--sidebar-text);
          text-decoration: none;
          background: $sidebar-nav-link-bg;
          @include borders($sidebar-nav-link-borders);
          @if $enable-sidebar-nav-rounded {
            border-radius: $border-radius;
          }

          &:hover {
            color: var(--sidebar-text);
            background: var(--sidebar-bg-hover) !important;
            @include borders($sidebar-nav-link-hover-borders);

            i {
              color: var(--sidebar-text);
            }
          }

          &.active {
            color: var(--sidebar-text);
            background: var(--sidebar-bg-active);
            @include borders($sidebar-nav-link-active-borders);

            i {
              color: var(--sidebar-text);
            }
          }

          [class^='icon-'],
          [class*=' icon-'] {
            display: inline-block;
            margin-top: -4px;
            vertical-align: middle;
          }

          i {
            width: 20px;
            margin: 0 (math.div($sidebar-nav-link-padding-x, 4) * 3) 0 0;
            font-size: 14px;
            color: var(--sidebar-text);
            text-align: center;
          }

          .tag {
            float: right;
            margin-top: 2px;
            // margin-left: 10px;
          }

          &.nav-dropdown-toggle {
            &::before {
              position: absolute;
              top: ($sidebar-nav-link-padding-y + math.div($font-size-base, 4));
              right: $sidebar-nav-link-padding-x;
              display: block;
              width: ($font-size-base);
              height: ($font-size-base);
              padding: 0;
              font-size: $font-size-base;
              line-height: math.div($font-size-base * 3, 4);
              text-align: center;
              content: '\2039';
              transition: 0.3s;
            }
          }
        }

        &.nav-dropdown {
          &.open {
            background: var(--sidebar-bg-dropdown);
            @include borders($sidebar-nav-dropdown-borders);
            @if $enable-sidebar-nav-rounded {
              border-radius: $border-radius;
            }
            > ul,
            > ol {
              // display: inline;
              max-height: 1000px;
            }

            a.nav-link {
              color: var(--sidebar-text);
              border-left: 0 !important;
            }

            > a.nav-link.nav-dropdown-toggle::before {
              -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
            }

            .nav-dropdown.open {
              border-left: 0;
            }
          }
          &.nt {
            transition: 0s !important;
            > ul,
            > ol {
              transition: 0s !important;
            }
            a.nav-link {
              &.nav-dropdown-toggle {
                &::before {
                  transition: 0s !important;
                }
              }
            }
          }
        }

        a.nav-label {
          display: block;
          padding: math.div($sidebar-nav-link-padding-y, 8) $sidebar-nav-link-padding-x;
          color: var(--sidebar-text);

          &:hover {
            color: var(--sidebar-text);
            text-decoration: none;
          }

          i {
            width: 20px;
            margin: -3px math.div($sidebar-nav-link-padding-x, 2) 0 0;
            font-size: 10px;
            color: var(--sidebar-text);
            text-align: center;
            vertical-align: middle;
          }
        }

        @if (lightness($sidebar-bg) < 40) {
          .progress[value] {
            background-color: lighten($sidebar-bg, 15%) !important;
          }

          // Unfilled portion of the bar
          .progress[value]::-webkit-progress-bar {
            background-color: lighten($sidebar-bg, 15%) !important;
          }
          base::-moz-progress-bar, // Absurd-but-syntactically-valid selector to make these styles Firefox-only
                    .progress[value] {
            background-color: lighten($sidebar-bg, 15%) !important;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  body.compact-nav {
    .sidebar {
      z-index: $zindex-navbar-fixed - 1;
      width: $sidebar-compact-width;
      .sidebar-nav {
        overflow: visible;
      }

      ul.nav {
        li.nav-title,
        li.divider {
          display: none;
        }

        li.nav-item {
          border-left: 0 !important;
          overflow: hidden;
          width: $sidebar-compact-width;

          ul {
            background: $sidebar-bg;
          }

          a.nav-link {
            position: relative;
            padding: 0 15px 0 0;
            margin: 0;

            line-height: $sidebar-compact-height;
            white-space: nowrap;
            border-left: 0 !important;

            &.nav-dropdown-toggle {
              &::before {
                display: none;
              }
            }

            i {
              display: block;
              float: left;
              width: $sidebar-compact-height;
              height: $sidebar-compact-height;
              padding: 0;
              margin: 0;
              font-size: 18px;
              line-height: $sidebar-compact-height;
            }

            .tag {
              position: absolute;
              top: 14px;
              right: 15px;
              display: none;
            }

            &:hover {
              width: $sidebar-width;

              .tag {
                display: inline;
              }
            }
          }

          ul {
            position: absolute;
            top: $sidebar-compact-width;
            left: $sidebar-compact-width;

            li {
              position: relative;
              padding: 0;

              a.nav-link {
                width: $sidebar-width - $sidebar-compact-width;
              }

              ul,
              ol {
                position: absolute;
                top: 0;
                left: 100%;
              }
            }
          }

          &.nav-dropdown {
            &.open {
              background: $sidebar-nav-link-active-bg;
              > a.nav-link i {
                color: var(--sidebar-text);
              }
              > ul,
              > ol {
                display: none;
              }
            }
          }

          &:hover {
            width: $sidebar-width + $sidebar-compact-width;
            overflow: visible;
            background: var(--sidebar-bg-hover);
            transition: 0s;

            > a.nav-link {
              width: $sidebar-width + $sidebar-compact-width;
            }

            > ul,
            > ol {
              max-height: 10000px;
              display: inline;
              transition: 0s;

              li {
                width: $sidebar-width;

                a.nav-link {
                  width: $sidebar-width;
                }
              }
            }

            &.nav-dropdown {
              &.open {
                > ul,
                > ol {
                  display: inline;
                }
              }
            }
          }
        }
      }
    }
  }
}
