html.sidebar-opened {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

.sidebar {
  left: -$sidebar-width;
  transition-duration: $layout-transition-speed, $layout-transition-speed, $layout-transition-speed,
    $layout-transition-speed, $layout-transition-speed, $layout-transition-speed;
  transition-property: left, right, padding-left, padding-right, margin-left, margin-right;
}

.main {
  padding-bottom: $footer-height;
  transition-duration: $layout-transition-speed, $layout-transition-speed, $layout-transition-speed,
    $layout-transition-speed;
  transition-property: padding-left, padding-right, margin-left, margin-right;
}

.aside-menu {
  transition-duration: $layout-transition-speed, $layout-transition-speed;
  transition-property: left, right;
}

.footer {
  transition-duration: $layout-transition-speed, $layout-transition-speed, $layout-transition-speed,
    $layout-transition-speed, $layout-transition-speed, $layout-transition-speed;
  transition-property: padding-left, padding-right, margin-left, margin-right, left, right;
}
