@use 'sass:math';

.card {
  margin-bottom: 1.5 * $spacer-y;
}

.card-header {
  i.icon-bg {
    display: inline-block;
    padding: $card-spacer-y $card-spacer-x !important;
    margin-top: -$card-spacer-y;
    margin-right: $card-spacer-x;
    margin-bottom: -$card-spacer-y;
    margin-left: -$card-spacer-x;
    line-height: inherit;
    color: $card-icon-color;
    vertical-align: bottom;
    background: $card-icon-bg;
    border-right: $card-border-width solid $card-border-color;
  }

  ul.nav.nav-tabs {
    margin-top: -$card-spacer-y;
    margin-bottom: -$card-spacer-y;
    border-bottom: 0;

    li.nav-item {
      border-top: 0;
      a.nav-link {
        padding: $card-spacer-y math.div($card-spacer-x, 2);
        color: $text-muted;
        border-top: 0;

        &.active {
          color: $body-color;
          background: #fff;
        }
      }
    }
  }

  &.card-header-inverse {
    color: #fff;
  }

  @each $variant,
    $color
      in (
        primary: $brand-primary,
        secondary: $gray-lighter,
        success: $brand-success,
        info: $brand-info,
        warning: $brand-warning,
        danger: $brand-danger
      )
  {
    &.card-header-#{$variant} {
      margin: -1px -1px 0;
      background: $color;
      border-bottom: 1px solid $color;
    }
  }

  .btn {
    margin-top: -$btn-padding-y;
  }
  .btn-sm {
    margin-top: -$btn-padding-y-sm;
  }
  .btn-lg {
    margin-top: -$btn-padding-y-lg;
  }
}
//
.card-footer {
  ul {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    table-layout: fixed;

    li {
      display: table-cell;
      padding: 0 $card-spacer-x;
      text-align: center;

      progress {
        margin-top: $card-spacer-y;
        margin-bottom: 0;
      }
    }
  }
}

//
// Background variations
//

@mixin card-variant($color) {
  background-color: $color;

  .card-header {
    border-color: darken($color, 5%);
  }

  .card-header,
  .card-footer {
    background-color: darken($color, 5%);
    //border: 0;
  }
}

@each $variant,
  $color
    in (
      primary: $brand-primary,
      secondary: $gray-lighter,
      success: $brand-success,
      info: $brand-info,
      warning: $brand-warning,
      danger: $brand-danger
    )
{
  .card-#{$variant} {
    @include card-variant($color);
  }
}

.card-inverse {
  color: #fff;

  .text-muted {
    color: rgba(255, 255, 255, 0.6) !important;
  }

  .progress[value] {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }

  // Unfilled portion of the bar
  .progress[value]::-webkit-progress-bar {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }
  base::-moz-progress-bar, // Absurd-but-syntactically-valid selector to make these styles Firefox-only
    .progress[value] {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }

  .progress {
    @include progress-variant(#fff !important);
  }
}

[class*='card-outline-'] {
  .card-block {
    background: #fff !important;
  }

  &.card-outline-top {
    border-top-width: 2px;
    border-right-color: $border-color;
    border-bottom-color: $border-color;
    border-left-color: $border-color;
  }
}

// Cards with color accent
.card-accent-primary {
  @include card-accent-variant($btn-primary-bg);
}
.card-accent-secondary {
  @include card-accent-variant($btn-secondary-border);
}
.card-accent-info {
  @include card-accent-variant($btn-info-bg);
}
.card-accent-success {
  @include card-accent-variant($btn-success-bg);
}
.card-accent-warning {
  @include card-accent-variant($btn-warning-bg);
}
.card-accent-danger {
  @include card-accent-variant($btn-danger-bg);
}
.card-reg {
  @include card-variant($brand-reg);
}
.card-waiting_payment {
  @include card-variant($brand-waiting_payment);
}

// Card Actions
.card-header {
  > i {
    margin-right: math.div($spacer-x, 2);
  }
  .card-actions {
    position: absolute;
    top: 0;
    right: 0;
    //height: inherit;

    a,
    button {
      display: block;
      float: left;
      width: 50px;
      padding: $card-spacer-y 0;
      margin: 0 !important;
      color: $body-color;
      text-align: center;
      background: transparent;
      border: 0;
      border-left: 1px solid $border-color;
      box-shadow: 0;

      &:hover {
        text-decoration: none;
      }

      [class^='icon-'],
      [class*=' icon-'] {
        display: inline-block;
        vertical-align: middle;
      }

      i {
        display: inline-block;
        transition: 0.4s;
      }

      i.r180 {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .input-group {
      width: 230px;
      margin: 6px;

      .input-group-addon {
        background: #fff;
      }

      input {
        border-left: 0;
      }
    }
  }
}

.card-full {
  margin-top: -$spacer-y;
  margin-right: -(math.div($grid-gutter-width-base, 2));
  margin-left: -(math.div($grid-gutter-width-base, 2));
  border: 0;
  border-bottom: $card-border-width solid $border-color;
}

@include media-breakpoint-up(sm) {
  .card-columns {
    -moz-column-count: 3;
    //-moz-column-count: 1.25rem;
    -webkit-column-count: 3;
    column-gap: 1.25rem;
    //-webkit-column-count: 1.25rem;
    column-count: 3;

    .card {
      display: inline-block;
      width: 100%; // Don't let them exceed the column width
    }

    &.col-2 {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
    }
  }
}

.card-group {
  margin-bottom: 1.5 * $spacer-y;
}
