//
// Display utilities
//

.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline {
  display: inline !important;
}
.d-flex {
  display: flex !important;
}
.d-none {
  display: none !important;
}
