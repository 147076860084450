.pagination-datatables,
.pagination {
  li {
    @extend .page-item;

    a {
      @extend .page-link;
    }
  }
}

.label-pill {
  border-radius: 1rem !important;
}
