footer.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: $footer-height;
  padding: 0 $spacer-x;
  line-height: $footer-height;
  color: $footer-color;
  background: $footer-bg;
  @include borders($footer-borders);
}
