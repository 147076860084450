// Core Admin Variables

$enable-sidebar-nav-rounded: false;

$border-color: $gray-lighter;
$layout-transition-speed: 0.25s;

// Social Colors

$facebook: #3b5998;
$twitter: #00aced;
$linkedin: #4875b4;
$google-plus: #d34836;
$flickr: #ff0084;
$tumblr: #32506d;
$xing: #026466;
$github: #4183c4;
$html5: #e34f26;
$openid: #f78c40;
$stack-overflow: #fe7a15;
$youtube: #b00;
$css3: #0170ba;
$dribbble: #ea4c89;
$google-plus: #bb4b39;
$instagram: #517fa4;
$pinterest: #cb2027;
$vk: #45668e;
$yahoo: #400191;
$behance: #1769ff;
$dropbox: #007ee5;
$reddit: #ff4500;
$spotify: #7ab800;
$vine: #00bf8f;
$foursquare: #1073af;
$vimeo: #aad450;

// Navbar

$navbar-height: 55px;
$navbar-bg: #fff;
$navbar-border: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
);
$navbar-brand-width: 155px;
$navbar-brand-bg: #fff;
$navbar-brand-logo: url('../../assets/img/logo.png');
$navbar-brand-logo-size: 70px auto;
$navbar-brand-border: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
);

$navbar-color: rgba(0, 0, 0, 0.3);
$navbar-hover-color: rgba(0, 0, 0, 0.6);
$navbar-active-color: rgba(0, 0, 0, 0.8);
$navbar-disabled-color: rgba(0, 0, 0, 0.15);

// Sidebar

$sidebar-width: 200px;
$sidebar-padding: 0;
$sidebar-compact-width: 50px;
$sidebar-compact-height: $sidebar-compact-width;
$sidebar-color: #fff;
$sidebar-bg: $gray-dark;
$sidebar-header-bg: rgba(0, 0, 0, 0.2);
$sidebar-footer-bg: rgba(0, 0, 0, 0.2);
$sidebar-borders: none;
$mobile-sidebar-width: $sidebar-width;

// Sidebar Navigation

$sidebar-nav-color: #fff;
$sidebar-nav-title-padding-y: 0.75rem;
$sidebar-nav-title-padding-x: 1rem;
$sidebar-nav-title-color: $gray-lighter;
$sidebar-nav-link-padding-y: 0.75rem;
$sidebar-nav-link-padding-x: 1rem;
$sidebar-nav-link-color: #fff;
$sidebar-nav-link-bg: transparent;
$sidebar-nav-link-icon-color: $text-muted;

$sidebar-nav-link-borders: 0;
$sidebar-nav-link-hover-color: #fff;
$sidebar-nav-link-hover-bg: $brand-primary;
$sidebar-nav-link-hover-icon-color: #fff !important;

$sidebar-nav-link-hover-borders: 0;
$sidebar-nav-link-active-color: #fff;
$sidebar-nav-link-active-bg: lighten($sidebar-bg, 5%);
$sidebar-nav-link-active-icon-color: $brand-primary;

$sidebar-nav-link-active-borders: 0;

$sidebar-nav-dropdown-color: #fff;
$sidebar-nav-dropdown-bg: rgba(0, 0, 0, 0.2);
$sidebar-nav-dropdown-borders: 0;

// Top Navigation

$top-nav-bg: #fff;
$top-nav-color: $body-color;
$top-nav-borders: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
);
$top-nav-ul-borders: (
  all: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
);

$top-nav-hover-color: #fff;
$top-nav-hover-bg: $brand-primary;
$top-nav-active-color: #fff;
$top-nav-active-bg: $brand-primary;
$top-nav-height: $navbar-height - 15px;

// Breadcrumb

$breadcrumb-borders: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
);

// Aside

$aside-menu-width: 250px;
$aside-menu-color: $gray-dark;
$aside-menu-bg: #fff;
$aside-menu-borders: (
  left: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
);

$aside-menu-nav-padding-y: 0.75rem;
$aside-menu-nav-padding-x: 1rem;

// Footer

$footer-height: 50px;
$footer-bg: $gray-lightest;
$footer-color: $body-color;
$footer-borders: (
  top: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
);

// Cards

$card-icon-bg: transparent;
$card-icon-color: $body-color;
