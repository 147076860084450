.main-ctn {
  background: #e4e5e6 url(/assets/img/footer.svg) no-repeat 0 var(--bg-size, calc(100% - 50px)) /
    contain;
}

.avatar {
  height: 36px;
}

.expired {
  color: gray;
  background-color: rgba(0, 0, 0, 0.1);
}

input[type='checkbox']:not(.default-input) {
  opacity: 0;
  left: 10px;
  z-index: 2;
  float: left;
  width: 25px;
  height: 25px;
  position: relative;
}

input[type='checkbox']:not(.default-input) + span.check {
  float: left;
  cursor: pointer;
  font-size: 16px;
  margin: 0 0 0 20px;
  position: relative;
  font-family: monospace;
}

input[type='checkbox']:not(.default-input) + span.check ~ span.check {
  margin: 0 0 0 40px;
}

input[type='checkbox']:not(.default-input) + span.check::before {
  left: -35px;
  width: 25px;
  content: ' ';
  height: 25px;
  display: block;
  position: absolute;
  background: $input-bg;
  border: 1px solid $input-border-color;
}

input[type='checkbox']:not(.default-input) + span.check::after {
  top: 4px;
  z-index: 1;
  opacity: 0;
  left: -31px;
  width: 17px;
  height: 17px;
  content: ' ';
  display: block;
  transition: 0.1s;
  position: absolute;
  background-color: $input-border-focus;
  -webkit-transform: scale(0);
  transform: scale(0);
}

input[type='checkbox']:not(.default-input):checked + span.check::after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

input[type='checkbox']:not(.default-input):checked + span.check::before {
  border-color: $input-border-focus;
}

tr:last-child {
  border-bottom: $table-border-width solid $table-border-color;
}

mark {
  padding: 0.2em 0;
  background: rgba($gray, 0.2);
}

.switch.switch-default > .switch-input {
  cursor: pointer;
}

.switch > .switch-input:disabled {
  cursor: default;
  ~ .switch-label {
    opacity: 0.33;
    cursor: default;
    ~ .switch-handle {
      opacity: 0.33;
      cursor: default;
    }
  }
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
}

.navbar .navbar-nav .nav-link::after {
  display: none;
}

.ui-widget-header {
  background: #cfd8dc !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-state-hover {
  background: #e6e6e6 !important;
}

.ui-datepicker .ui-datepicker-prev {
  top: 2px !important;
  left: 2px !important;
}

.ui-datepicker .ui-datepicker-next {
  top: 2px !important;
  right: 2px !important;
}

.text-md-right {
  text-align: right !important;
}

.mb-0.perso {
  margin-bottom: 1rem !important;
}

tu-order-detail .col-md-6 {
  float: left;
  width: 50%;
}

tu-order-detail .float-md-left {
  float: left !important;
}

.input-group-addon,
.input-group-btn {
  user-select: none;
}

.avatar-input-group .input-group-addon {
  width: auto;
}

tu-customer-detail .card {
  min-height: 100px;
}

tu-colors .card-header {
  display: flex;
  flex-wrap: wrap;
}

.card-header > i {
  margin-top: 0.2em;
}

.card-header h6 {
  flex: 1;
  align-self: center;
}

.btr-dateinput-value {
  margin-left: 3em;
  padding: 7px 12px 6px !important;
}

.avatar-input-group,
.avatar-input-group span,
.avatar-input-group input {
  display: inline-block;
}

footer.footer {
  left: 0 !important;
}

.d-100vh-va-middle {
  height: calc(100vh - 50px);
}

time[is='local-time'] {
  z-index: 5 !important;
}

[type='date'].form-control {
  padding: 0.4rem 0.75rem 0.3rem;
}

.btr-dateinput-calendar-header,
.btr-dateinput-calendar-header > a:hover,
td.btr-dateinput-calendar-today:hover,
.btr-dateinput-calendar-today {
  background: #162636;
}

.btr-dateinput-calendar {
  top: 1px;
  left: 3.1em;
  border-radius: 0;
  z-index: 6 !important;
}

.btr-dateinput-calendar-header,
.btr-dateinput-calendar-days > thead,
.btr-dateinput-calendar-today {
  text-shadow: none;
  border-bottom: none;
}

h6.pt-1 {
  padding-top: 0.45rem !important;
}

.avatar-input-group {
  position: relative;
}

.charts {
  display: flex;
  flex-wrap: wrap;

  > div {
    display: flex;
  }

  > div > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .chart-wrapper {
    flex-grow: 1;
  }
}

canvas.chart {
  display: block !important;
}

span[lang='en'] {
  display: none !important;
}

h6.table-msg {
  font-weight: normal;
}

div.fr-popup,
div.fr-modal .fr-modal-wrapper,
div.fr-toolbar {
  color: #162636;
  border-color: #162636;

  button.fr-command.fr-btn {
    color: #162636;
  }
}

div.fr-qi-helper a.fr-btn.fr-floating-btn,
div.fr-modal .fr-modal-wrapper .fr-modal-head i,
div.fr-modal,
.fr-toolbar .fr-command.fr-btn.fr-dropdown::after,
.fr-popup .fr-command.fr-btn.fr-dropdown::after {
  color: #162636;
}

div.fr-box.fr-basic .fr-element {
  min-height: 20vh;
}

div.fr-wrapper .fr-placeholder {
  color: #797979;
}

.sr-only {
  border: 0 !important;
  width: 1px !important;
  padding: 0 !important;
  height: 1px !important;
  z-index: -1 !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  pointer-events: none !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
}

.ng-valid[required]:not(.required-field ),
.ng-valid.required:not(.required-field ) {
  border-left: 5px solid #42a948 !important;
  /* green */
}

.ng-invalid:not(form):not(.no-error):not(.mat-form-field):not(.mat-select):not(
    .mat-form-field input
  ) {
  border-left: 5px solid #a94442 !important;
  /* red */
}

.mat-form-field.required-field {
  &.ng-invalid {
    .mat-form-field-outline-start {
      background: #a94442 !important;
    }
  }
  
  &.ng-valid {
    .mat-form-field-outline-start {
      background: #42a948 !important;
    }
  }
}

.mat-select-required {
  &.mat-select-empty {
    border-left: 5px solid #a94442 !important;
  }
}

// Remove padding bottom on card block
.ng2-pagination,
.card .card,
.form-group:last-of-type {
  margin-bottom: 0 !important;
}

// search list
.search-item .dropdown-menu {
  width: calc(100% - 2rem);
}

.tooltip_custom {
  font-size: 0.9rem;
  line-height: 1.5;
}

/* IE 10&11 only */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .btr-dateinput-value {
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  footer.footer {
    display: block;
    left: 0 !important;
    transform: none;
    transition: transform 0.15s !important;
  }
}

@media only screen and (max-width: 800px) {
  .avatar-input-group,
  .avatar-input-group input {
    width: 100% !important;
  }

  .avatar-input-group:first-of-type {
    margin-bottom: 1.5em !important;
  }
}

@media only screen and (max-width: 768px) {
  .col-md-4 {
    width: 50%;
  }

  .mr-responsive[_ngcontent-hav-29] {
    margin-right: 1.5rem !important;
  }

  .ml-responsive[_ngcontent-hav-29] {
    margin-left: 1.5rem !important;
  }

  tu-customer-detail .col-md-4 {
    width: 100%;
  }

  tu-customer-detail .card-header .col-xs-12 {
    width: auto;
  }

  tu-customer-detail .text-xs-center {
    text-align: left !important;
  }

  tu-customer-detail .card {
    min-height: 0;
  }
}

@media only screen and (max-width: 565px) {
  .col-sm-6 {
    width: 100%;
  }

  .card-header * {
    width: auto;
  }

  .card-header a,
  .card-header p {
    word-break: break-all;
    -ms-word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  .card-block .col-md-4,
  .avatar-input {
    width: 100% !important;
  }

  .card-block .col-xs-12,
  .text-xs-left {
    text-align: center !important;
  }

  .mr-2 {
    margin: 0 !important;
  }

  tu-customer-detail .card-header .col-xs-12,
  .perso-d .row {
    width: 100% !important;
  }

  tu-customer-detail .card-header:first-of-type {
    padding: 0.75rem;
  }

  tu-customer-detail .card-header .col-xs-12 img {
    display: block;
    margin: auto;
    margin-bottom: 1.5em;
  }

  tu-customer-detail div.text-xs-center {
    text-align: center !important;
  }

  tu-order-detail .float-md-left {
    margin-bottom: 2em;
  }

  tu-order-detail .float-md-right,
  tu-order-detail .float-md-left {
    float: none !important;
    text-align: center !important;
  }

  tu-order-detail .col-md-6 {
    width: 100%;
  }

  tu-order-detail .card-header p,
  tu-order-detail .card-header .mb-0.perso {
    margin: 0.25em !important;
  }

  tu-order-detail .perso-d .float-md-left.text-xs-center {
    text-align: left !important;
  }

  tu-order-detail .perso-d .text-xs-center:last-of-type {
    margin: 0;
  }
}

@media only screen and (max-width: 460px) {
  tu-colors .card-header:first-of-type > * {
    width: 100%;
    text-align: left !important;
  }

  tu-colors .card-header:first-of-type h6 {
    margin-bottom: 1.25em;
  }
}

@media only screen and (max-width: 380px) {
  tu-colors .img-avatar.avatar.p-2.p-responsive {
    padding: 1.5rem 1.5rem !important;
  }

  button.btn.btn-outline-primary.btn-responsve {
    padding: 0.4rem 0.75rem;
  }
}

// FIX MAT SELECT
mat-form-field[floatlabel='never'] {
  .mat-select-arrow-wrapper {
    transform: translateY(0) !important;
  }

  .mat-form-field-infix {
    border-top: 0 !important;
  }

  .mat-form-field-outline {
    top: 0 !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  &.has-hint .mat-form-field-wrapper {
    padding-bottom: 1.34375em !important;
  }

  .form-control:disabled {
    background-color: transparent !important;
  }
}

.mat-select-disabled {
  opacity: 0.33;
}

.loader {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;

  width: 60px;
  padding: 10px;
  background-color: #e3e4e5;
  border-radius: 10%;
  opacity: 0.8;

  .spinner {
    border: 5px solid white; /* Light grey */
    border-top: 5px solid #98abba; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
}

.selected {
  background-color: #eceff1 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
