// Bootstrap overrides

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

/*$gray-dark:                 #263238;
$gray:                      #607d8b;
$gray-light:                #b0bec5;
$gray-lighter:              #cfd8dc;
$gray-lightest:             #eceff1;

$brand-primary:             #20a8d8;
$brand-success:             #4dbd74;
$brand-info:                #63c2de;
$brand-warning:             #f8cb00;
$brand-danger:              #f86c6b;*/

$gray-dark: #162636;
$gray: #607d8b;
$gray-light: #b0bec5;
$gray-lighter: #cfd8dc;
$gray-lightest: #eceff1;

$brand-primary: #67a5ee;
$brand-success: #4dbd74;
$brand-info: #afccee;
$brand-warning: #f8cb00;
$brand-danger: #f86c6b;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: false;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    q: (
      $spacer * 0.25,
    ),
    h: (
      $spacer * 0.5,
    ),
    0: 0,
    1: $spacer,
    2: (
      $spacer * 1.5,
    ),
    3: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// Body
//
// Settings for the `<body>` element.

$body-bg: #e4e5e6;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;

// Breadcrumbs

$breadcrumb-bg: #fff;

// Cards

$card-border-color: $gray-lighter;
$card-cap-bg: $gray-lightest;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-lighter;
$dropdown-divider-bg: $gray-lightest;

// Buttons

$btn-secondary-border: $gray-light;

// Progress bars

$progress-bg: $gray-lightest;

// Tables

$table-bg-accent: $gray-lightest;
$table-bg-hover: $gray-lightest;
