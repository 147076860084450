.chart-legend,
.bar-legend,
.line-legend,
.pie-legend,
.radar-legend,
.polararea-legend,
.doughnut-legend {
  list-style-type: none;
  margin-top: 5px;
  text-align: center;
  -webkit-padding-start: 0;
  -moz-padding-start: 0;
  padding-left: 0;
}
.chart-legend li,
.bar-legend li,
.line-legend li,
.pie-legend li,
.radar-legend li,
.polararea-legend li,
.doughnut-legend li {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  margin-bottom: 4px;
  @include border-radius($border-radius);
  padding: 2px 8px 2px 28px;
  font-size: smaller;
  cursor: default;
}
.chart-legend li span,
.bar-legend li span,
.line-legend li span,
.pie-legend li span,
.radar-legend li span,
.polararea-legend li span,
.doughnut-legend li span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  @include border-radius($border-radius);
}
